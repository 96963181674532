module.exports = {
  title: 'Dev Blog', // Required
  author: 'Damnjan Luković', // Required
  description: 'Software Developer',
  primaryColor: '#3498db', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    github: 'https://github.com/damnjan',
    linkedin: 'https://linkedin.com/in/damnjan',
  },
  pathPrefix: '/devblog',
  siteUrl: 'https://www.damnjan.dev',
};
